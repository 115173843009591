
// JQuery Stuff:
import "./import-jquery";

import "./import-feather-icons";
import "jquery-sticky";

import Cookies from "js-cookie";
import deparam from "jquery-deparam";

// Bootstrap Stuff:
import * as Popper from "@popperjs/core"
import { Offcanvas, Popover } from 'bootstrap';
// import "./bs-toggle-color-mode";

// Slider Pro:
import "../../node_modules/slider-pro-js/build/slider-pro";
// import "../../node_modules/daterangepicker/daterangepicker";
import {
	TempusDominus,
	DefaultOptions,
	DefaultEnLocalization,
	DateTime, loadLocale, locale
} from "@eonasdan/tempus-dominus";

import * as TempusDominus_locale_de from "../../node_modules/@eonasdan/tempus-dominus/dist/locales/de";


import ds81_initAutovalidatorForm from './jq-autovalidator-form_import.js';
import {dsApplicationFormSizeTextarea, dsInitApplicationFormValidation} from "./jq-application-form";

import {ds81_newsletter_init_subscribe_forms } from './jq-newsletter_signon_form';


import {formValidation, plugins} from "./lib/formvalidation_1_8_1/es6/";
import Bootstrap5 from "./lib/formvalidation_1_8_1/es6/plugins/Bootstrap5";
import * as Ladda from "ladda";
import moment from 'moment';
import 'moment/locale/de';
moment.locale('de');

import _ from "underscore";

import './lib/jquery.pause.min';

import "./jq-timed-message";



export default (window._ = _, window.moment = moment, window.ds81_sizeFormTextArea = ds81_sizeFormTextArea);

// The debounce function receives our function as a parameter
const debounce = (fn) => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame;

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
            cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
            // Call our function and pass any params we received
            fn(...params);
        });
    };
};

const tempusDominus_icons = {
	type: 'icons',
	time: 'ci ci-clock',
	date: 'ci ci-calendar',
	up: 'ci ci-calendar-up',
	down: 'ci ci-calendar-down',
	previous: 'ci ci-calendar-prev',
	next: 'ci ci-calendar-next',
	today: 'ci ci-calendar-check',
	clear: 'ci calendar-trash',
	close: 'ci ci-close'
};

const setHeaderBgBarState = () => {
    var switchVal = isWithinBreakpoint('xs') || isWithinBreakpoint('sm') ? 20 : 10;
    if(window.scrollY > switchVal)
    {
        if(!document.getElementsByTagName('html')[0].classList.contains('nav-down'))
        {
            document.getElementsByTagName('html')[0].classList.add('nav-down');
            ds81_breadcrumb_sticky();
        }
    }
    else
    {
        if(document.getElementsByTagName('html')[0].classList.contains('nav-down'))
        {
            document.getElementsByTagName('html')[0].classList.remove('nav-down');
            ds81_breadcrumb_sticky();
        }
    }
};
document.addEventListener( 'DOMContentLoaded', () => {

    // Listen for new scroll events, here we debounce our `setHeaderBgBarState` function
    document.addEventListener('scroll', debounce(setHeaderBgBarState));
    setHeaderBgBarState();

    // Replace feather icons:
    feather.replace();

    // Init hero slider if any:
    if($('.hero-slider').length > 0)
    {

        const heroSlider = new SliderPro( '.hero-slider', {

            width: '100vw',
            height: '100svh',
            autoplay: true,
            autoplayDelay: 11000,
            fadeDuration: 4000,
            autoplayOnHover: 'none',
            loop: true,
            fade: true,
            arrows: false,
            buttons: false,
            touchSwipe: false,
            centerImage: true
        });

        // // the slider will go to the next slide when the document is clicked
        // document.addEventListener( 'click', () => {
        //     heroSlider.nextSlide();
        // });

        window.addEventListener( 'resize', () => {
            heroSlider.update();
        });

    }

    document.addEventListener('visibilitychange', () => {
        $('.fog-enabled').removeClass('fog-enabled').addClass('fog-enabled');
    });

	// Autovalidator Form init:
	$('form.autovalidator-form').each(function () {
		ds81_init_autovalidator_form(
			$(this),
			null,
			null,
			function ($form, event) {
				// Callback on invalid form
				ds81_sizeFormTextArea($form);
			},
			function ($form, event) {
				// Callback on invalid form
				ds81_sizeFormTextArea($form);
			},
			function ($form, event) {
				// Callback on invalid form
				ds81_sizeFormTextArea($form);
			});
	});

	// Init nl subscribe forms:
	ds81_newsletter_init_subscribe_forms();

	// Size form cols:
	ds81_sizeFormTextArea();


	$('.application-form').each(function () {

		var $form = $(this);

		if(!$form.hasClass('ds-form-appl-init-done'))
		{
			$form.addClass('ds-form-appl-init-done');

			$('[data-formstep="thankyou"]').slideUp(0).removeClass('d-none');
			dsInitApplicationFormValidation($form);

			$form.find('.ds-selectpicker-cf').on('loaded.bs.select', function () {
				var ph = $(this).attr('data-onselect-placeholder');
				$(this).parent().find('.btn.dropdown-toggle').attr('data-onselect-placeholder', ph);
			}).on('changed.bs.select', function () {
				setTimeout(dsApplicationFormSizeTextarea, 10);
				var fv = $(this).closest('form').data('form-fv');
				fv.revalidateField($(this).attr('name'));
			});


			// Sizer:
			if($form.find('textarea.size-to-col').length > 0)
			{
				$(window).on('resize.dsApplicationFormSizeTextarea', dsApplicationFormSizeTextarea);
				dsApplicationFormSizeTextarea();

				$form.find('input').on('input', dsApplicationFormSizeTextarea);
				$form.find('textarea').on('input', dsApplicationFormSizeTextarea);
				$form.find('select').on('input', dsApplicationFormSizeTextarea);

			}
		}

	});



	$('input.input-appointment-time').each(function () {
		new TempusDominus($(this).get(0),
			{
				allowInputToggle: false,
				container: undefined,
				dateRange: false,
				debug: false,
				defaultDate: undefined,
				display: {
					icons: tempusDominus_icons,
					sideBySide: false,
					calendarWeeks: false,
					viewMode: 'clock',
					toolbarPlacement: 'bottom',
					keepOpen: false,
					buttons: {
						today: false,
						clear: false,
						close: false
					},
					components: {
						calendar: false,
						date: false,
						month: false,
						year: false,
						decades: false,
						clock: true,
						hours: true,
						minutes: true,
						seconds: false,
					},
					inline: false,
					theme: 'auto'
				},
				keepInvalid: false,
				localization: _.extend(_.clone(TempusDominus_locale_de.localization), {format: 'LT'}),
				meta: {},
				multipleDates: false,
				multipleDatesSeparator: '; ',
				promptTimeOnDateChange: false,
				promptTimeOnDateChangeTransitionDelay: 200,
				restrictions: {
					minDate: undefined,
					maxDate: undefined,
					disabledDates: [],
					enabledDates: [],
					daysOfWeekDisabled: [],
					disabledTimeIntervals: [],
					disabledHours: [],
					enabledHours: []
				},
				stepping: 1,
				useCurrent: true,
				viewDate: new DateTime()
			})
	});


	$('input.input-appointment-date').each(function () {
		new TempusDominus($(this).get(0),
			{
				allowInputToggle: false,
				container: undefined,
				dateRange: false,
				debug: false,
				defaultDate: undefined,
				display: {
					icons: tempusDominus_icons,
					sideBySide: false,
					calendarWeeks: true,
					viewMode: 'calendar',
					toolbarPlacement: 'bottom',
					keepOpen: false,
					buttons: {
						today: false,
						clear: false,
						close: false
					},
					components: {
						calendar: true,
						date: true,
						month: true,
						year: true,
						decades: false,
						clock: false,
						hours: false,
						minutes: false,
						seconds: false,
					},
					inline: false,
					theme: 'auto'
				},
				keepInvalid: false,
				localization: _.extend(_.clone(TempusDominus_locale_de.localization), {format: 'L'}),
				meta: {},
				multipleDates: false,
				multipleDatesSeparator: '; ',
				promptTimeOnDateChange: false,
				promptTimeOnDateChangeTransitionDelay: 200,
				restrictions: {
					minDate: moment().toDate(),
					maxDate: moment().add(12,'month').toDate(),
					disabledDates: [],
					enabledDates: [],
					daysOfWeekDisabled: [],
					disabledTimeIntervals: [],
					disabledHours: [],
					enabledHours: []
				},
				stepping: 1,
				useCurrent: true,
				viewDate: new DateTime()
			});

		// Clear if invalid:
		$(this).on('change.td', function (evt) {
			$(this).closest('form').data('form-fv').revalidateField($(this).attr('name'));
		});
		$(this).on('error.td', function (evt) {
			if(evt.isValid !== true)
			{
				$(this).val('');
			}
			$(this).closest('form').data('form-fv').revalidateField($(this).attr('name'));
		});


	});


	$('[data-bs-toggle="popover"][data-bs-trigger="focus"]').each(function () {
		const popover = new Popover($(this).get(0), {
			trigger: 'focus',
			options: {
				fallbackPlacements: ['bottom', 'right'],
			},
		});
	});

	// Handle changes in consent selection:
	$(window).on('dscookiebar_consentchange', dscookiebar_handle_consent_change);
	dscookiebar_handle_consent_change();


	// Check and handle cookie bar show/hide:
	if($('#dscookiebar_consent').length > 0)
	{
		// Consent version id:
		var cookieVersion = $('#dscookiebar_consent').first().data('version');

		// Cookie path:
		var cookiePath = typeof path_root !== "undefined" ? path_root : '';

		// Read cookie:
		if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) === "undefined" || Cookies.get('dscookieconsent_version', {path: cookiePath}) != cookieVersion)
		{
			$('#dscookiebar_consent').each(function () {

				// Prevent form submit:
				$(this).find('form').submit(function(e){
					e.preventDefault();
				});

				// Link select checkboxes:
				$(this).find('input[type="checkbox"]').each(function (){
					if(typeof $(this).attr('checked') !== "undefined"){
						$(this).prop('checked', true);
					}
				});
				$(this).find('input[type="checkbox"]').on('change', function () {
					// Transfer status to possibly present cookie bar:
					$('#dscookiebar_onpage_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));
				});

				$(this).removeClass('d-none');
				$(this).find('.content-select-consent').addClass('d-none');
				$(this).find('.content-default').removeClass('d-none');

				$(this).find('.btn-accept-all').on('click.dscookiebaracceptall', dscookiebar_accept_all_handler);
				$(this).find('.btn-make-choice').on('click.dscookiebarmakechoice', dscookiebar_show_select_consent_content);
				$(this).find('.btn-cancel-consent-select').on('click.dscookiebarcancel', dscookiebar_show_default_content);
				$(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);
			});

		}

	}

	// Link onpage component:
	if($('#dscookiebar_onpage_consent').length > 0)
	{
		$('#dscookiebar_onpage_consent').each(function () {

			// Prevent form submit:
			$(this).submit(function(e){
				e.preventDefault();
			});

			// Link select checkboxes:
			$(this).find('input[type="checkbox"]').each(function (){
				if(typeof $(this).attr('checked') !== "undefined"){
					$(this).prop('checked', true);
				}
			});

			// Process checkboxes:
			$(this).find('input[type="checkbox"]').each(function () {

				// Set initial state:
				if(dscookiebar_has_consent($(this).attr('name')))
				{
					$(this).prop('checked', true);
				}


				// Link checkbox to transfer state to cookie bar:
				$(this).on('change', function () {

					// Transfer status to possibly present cookie bar:
					$('#dscookiebar_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));

					// Check status:
					dscookiebar_onpage_check_status();

				});

			});

			// Link save button:
			$(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

		});

		// Listen to change events to update onpage status:
		$(window).on('dscookiebar_consentchange', function () {

			// Check status:
			dscookiebar_onpage_check_status();

		});

		// Initial status check:
		dscookiebar_onpage_check_status();

	}


});


// set variables
var xs;
var sm;
var md;
var lg;
var xl;
var xxl;
var breakpoint;

// Breakpoint check helper functions ----
// Checks if the span is set to display lock via CSS
function checkIfBlock (target) {
    var target = $(target).css('display') == 'block';
    return target;
}

// function to check the sizes
function checkSize (){
    // Set some variables to use with the if checks below

    xs = checkIfBlock('.breakpoint-check .xs');
    sm = checkIfBlock('.breakpoint-check .sm');
    md = checkIfBlock('.breakpoint-check .md');
    lg = checkIfBlock('.breakpoint-check .lg');
    xl = checkIfBlock('.breakpoint-check .xl');
    xxl = checkIfBlock('.breakpoint-check .xxl');

// add the breakpoint to the console
// console.clear();
    if( xs == true) {
        breakpoint = "xs - <576px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "xs" );
    }

    if( sm == true) {
        breakpoint = "sm - ≥576px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl xxl').addClass( "sm" );
    }

    if( md == true) {
        breakpoint = "md - ≥768px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl xxl').addClass( "md" );
    }

    if( lg == true) {
        breakpoint = "lg - ≥992px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl xxl').addClass( "lg" );
    }

    if( xl == true) {
        breakpoint = "xl - ≥1200px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl xxl').addClass( "xl" );
    }

    if( xxl == true) {
        breakpoint = "xl - ≥1400px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl xxl').addClass( "xxl" );
    }

}
// end check size


function getBreakPointName() {
    if(typeof breakpoint === 'undefined')
    {
        return 'lg';
    }
    return breakpoint.substr(0,2);
}

function isWithinBreakpoint(checkAgainst)
{
    return getBreakPointName() === checkAgainst.toLowerCase();
}
// END Breakpoint check helper functions ----

// jQuery on ready:
$(function() {

    // Add js flag to html:
    $('html').addClass('js-on');

    // Breakpoint check
    $( "body" ).append( "<div style='display:none;' class='breakpoint-check'><span class='xs d-block d-sm-inline'></span><span class='sm d-sm-block d-md-inline'></span><span class='md d-md-block d-lg-inline'></span><span class='lg d-lg-block d-xl-inline'></span><span class='xl d-xl-block'></span><span class='xxl d-xxl-block'></span></div>" );
    checkSize();

    // Init bs offcanvas:
    $('#ocMainNav').data('bs-oc-ref', new Offcanvas('#ocMainNav'));

    // Set bs offcanvas handlers:
    $('#ocMainNav').on('show.bs.offcanvas', event => {
        $('.oc-nav-trigger').addClass('is-active');
        $('html').addClass('menu-show');
        $('.offcanvas-backdrop').on('click', function (e) {
        });
    });

    $('#ocMainNav').on('hide.bs.offcanvas', event => {
        $('.oc-nav-trigger').removeClass('is-active');
        $('html').removeClass('menu-show');
    });



    // Add click handler to open/close bs offcanvas menu on main menu button click:
    $('.oc-nav-trigger').on('click.nav-main-toggle',function (e) {
		e.preventDefault();
		e.stopPropagation();
        if($('html').hasClass('menu-show'))
        {
            $('#ocMainNav').data('bs-oc-ref').hide();
        }
        else
        {
            $('#ocMainNav').data('bs-oc-ref').show();
        }
    });


    $(window).on('resize.ds81_breadcrumb_sticky', function () {
        if($('html').data('check-breakpoint') !== getBreakPointName())
        {
            $('html').data('check-breakpoint', getBreakPointName());
            $('html').remove('nav-down');
            setHeaderBgBarState();
        }
    });
    $(window).on('resize.ds81_breakpoint_check', debounce(checkSize));
    ds81_breadcrumb_sticky();


    // Sticky aside block large screens:
    $("aside.aside-lg").unstick().sticky({
        topSpacing: 100,
    })/*.on('sticky-start', function() {
        console.log('STICK START');
    }).on('sticky-end', function() {
        console.log('STICK END');
    })*/;

});

function ds81_breadcrumb_sticky(tsValue){

    var defaultTopSpace = isWithinBreakpoint('xs') || isWithinBreakpoint('sm') ? 54 : 85;

    var topSpace = typeof tsValue === "undefined" ? defaultTopSpace : tsValue;

    // Sticky Breadcrumb:
    $(".breadcrumb-wrapper").unstick().sticky({
        topSpacing: topSpace,
        zIndex: 1039,
        getWidthFrom: $('header')
    })/*.on('sticky-start', function() {
        console.log('STICK START');
    }).on('sticky-end', function() {
        console.log('STICK END');
    })*/;
}

function ds81_sizeFormTextArea($target)
{
	if(typeof $target === "undefined")
	{
		$target = $('form');
	}

	$target.each(function () {
		if($(this).find('textarea.size-to-col').length > 0)
		{
			var $ta = $(this).find('textarea.size-to-col');
			var $r = $ta.closest('form');
			var $refCol = $r.find('.measure-col');
			var $refField = $r.find('.measure-input');

			var measureColPosition = $refCol.offset().top;
			var measureFieldPosition = $refField.offset().top;
			var blockHeight = measureFieldPosition - measureColPosition + $refField.outerHeight();

			$ta.css('height', blockHeight + 'px');

		}
	});
}



function dscookiebar_handle_consent_change()
{
	// Update onpage instances:
	var $targets = $('body').find('.dscookiebar,.consent-item-list-onpage');
	$targets.find('input[type="checkbox"]').each(function () {

		// Set current state:
		if (dscookiebar_has_consent($(this).attr('name'))) {
			$(this).prop('checked', true);
		}
		else
		{
			$(this).prop('checked', false);
		}

	});


	// Google Analytics:
	if(dscookiebar_has_consent('ga'))
	{
		dscookiebar_load_and_fire_tracking_calls();
	}

	// Show/hide consent elements:
	$('[data-consent-required]').each(function (){

		// Get consent id:
		var myConsent = $(this).data('consent-required');

		$('.addconsent').on('click', function (e){
			e.preventDefault();
			dscookiebar_add_consent($(this).data('addconsent'));
		});

		if(dscookiebar_has_consent(myConsent))
		{
			// Manage show/hide:
			$(this).find('[data-ifconsent="yes"]').removeClass('d-none');
			$(this).find('[data-ifconsent="no"]').addClass('d-none');

			// Parse template:
			var pluginTemplate = $(this).find('[data-ifconsent="yes"] .plugin-template').first();
			if(pluginTemplate.length > 0)
			{
				// console.log('IN');
				var tpl = _.template(pluginTemplate.html());
				var tplRendered = tpl({});
				$(this).find('[data-ifconsent="yes"]').html(tplRendered);
			}
		}
		else
		{
			// Manage show/hide:
			$(this).find('[data-ifconsent="yes"]').addClass('d-none');
			$(this).find('[data-ifconsent="no"]').removeClass('d-none');
		}

	});

}

function dscookiebar_add_consent(addConsent, callback){

	var $bar = $('body .dscookiebar').first();

	// Consent version id:
	var cookieVersion = $bar.data('version');

	// All IDs:
	var cookieIDs = $bar.data('cookies-list');

	// Consent found?
	var newConsentFound = false;

	var cookieStringList = [];
	for(var i in cookieIDs)
	{
		if(cookieIDs[i] == addConsent)
		{
			newConsentFound = true;
		}
		var setValue = 0;
		if(dscookiebar_has_consent(cookieIDs[i]) || cookieIDs[i] == addConsent)
		{
			setValue = 1;
		}
		cookieStringList.push(cookieIDs[i] + '=' + setValue);
	}
	var cookieString = cookieStringList.join('&');

	// Cookie path:
	var cookiePath = typeof path_root !== "undefined" ? path_root : '';

	// Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
	Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
	Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

	// Trigger event:
	$(window).trigger('dscookiebar_consentchange');

	// Callback?
	if(typeof callback == 'function')
	{
		// console.log('FN A', arguments);
		callback(arguments[2], arguments[3], arguments[4], arguments[5]);
	}

}


function dscookiebar_onpage_check_status() {

	// Check current status and enable save button if onpage settings do not match current consent:
	if($('#dscookiebar_onpage_consent').length > 0)
	{
		$('#dscookiebar_onpage_consent').each(function () {

			// Flag: All settings match current selection:
			var allMatch = true; // Assume true first.

			// Process checkboxes and check selection:
			$(this).find('input[type="checkbox"]').each(function () {

				// Check status:
				if(dscookiebar_has_consent($(this).attr('name')) != $(this).prop('checked'))
				{
					// No match. Checkbox state does not equal current consent.
					allMatch = false;
				}

			});

			// Set button status:
			$(this).find('.btn-save-consent-settings').prop('disabled', allMatch);
			if(allMatch)
			{
				$(this).find('.message-all-up-to-date').removeClass('d-none');
			}
			else
			{
				$(this).find('.message-all-up-to-date').addClass('d-none');
			}

		});
	}

}

function dscookiebar_has_consent(which) {
	var allConsents = dscookiebar_get_consents();
	return jQuery.inArray( which, allConsents) >= 0;
}

function dscookiebar_get_consents() {

	// Consent version id:
	var cookieVersion = $('#dscookiebar_consent').first().data('version');

	// Cookie path:
	var cookiePath = typeof path_root !== "undefined" ? path_root : '';

	// Prepeare return array:
	var ret = [];

	// Read cookie:
	if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) !== "undefined" && Cookies.get('dscookieconsent_version', {path: cookiePath}) == cookieVersion)
	{
		// Get cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
		var consentData = Cookies.get('dscookieconsent', { path: cookiePath });

		// Data from cookie obtained?
		if(typeof consentData !== "undefined")
		{
			var consentArray = deparam(consentData);
			for(var i in consentArray)
			{
				if(parseInt(consentArray[i]) > 0)
				{
					ret.push(i);
				}
			}
		}

	}

	// Return given consents:
	return ret;

}

function dscookiebar_hide_bar($bar) {

	$('html').removeClass('dscookiebar-show-cover');

	$bar.find('.inner').animate({
		// height: 0,
		opacity: 0,
	}, {
		duration: 300,
		complete: function () {
			$(this).closest('.dscookiebar').hide();
		}
		// ,
		// step: function (now, tween) {
		// $(this).parent().css('height', $(this).outerHeight() + 'px');
		// }
	});

}

function dscookiebar_accept_all_handler(e) {

	if(typeof e !== "undefined")
	{
		e.preventDefault();
	}

	// Get references:
	var $button = $(e.currentTarget);
	var $bar = $button.closest('.dscookiebar');

	dscookiebar_hide_bar($bar);

	// Consent version id:
	var cookieVersion = $bar.data('version');

	// All IDs:
	var cookieIDs = $bar.data('cookies-list');
	var cookieStringList = [];
	for(var i in cookieIDs)
	{
		cookieStringList.push(cookieIDs[i] + '=1');
	}
	var cookieString = cookieStringList.join('&');

	// Cookie path:
	var cookiePath = typeof path_root !== "undefined" ? path_root : '';

	// Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
	Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
	Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

	// Trigger event:
	$(window).trigger('dscookiebar_consentchange');

}

function dscookiebar_show_select_consent_content(e) {

	e.preventDefault();

	var $button = $(e.currentTarget);
	var $bar = $button.closest('.dscookiebar');

	$bar.find('.content-select-consent').removeClass('d-none');
	$bar.find('.content-default').addClass('d-none');
	$('html').addClass('dscookiebar-show-cover');

}

function dscookiebar_show_default_content(e) {

	e.preventDefault();

	var $button = $(e.currentTarget);
	var $bar = $button.closest('.dscookiebar');

	$bar.find('.content-select-consent').addClass('d-none');
	$bar.find('.content-default').removeClass('d-none');
	$('html').removeClass('dscookiebar-show-cover');
}

function dscookiebar_save_consent(e) {

	e.preventDefault();

	var $button = $(e.currentTarget);
	var $form = $button.closest('form');

	// Find bar to obtain data:
	var $bar = $('#dscookiebar_consent').first();

	var checked = [];
	$form.find('input.consent-check:checked').each(function () {
		checked.push($(this).attr('name'));
	});

	dscookiebar_hide_bar($bar);

	// Consent version id:
	var cookieVersion = $bar.data('version');

	// All IDs:
	var cookieIDs = $bar.data('cookies-list');
	var mandatoryIDs = $bar.data('mandatory-list');
	var cookieStringList = [];
	for(var i in cookieIDs)
	{
		var myId = cookieIDs[i];
		var myState = 0;

		// Selected or mandatory?
		if(jQuery.inArray( myId, checked) >= 0 || jQuery.inArray( myId, mandatoryIDs) >= 0)
		{
			myState = 1;
		}

		cookieStringList.push(myId + '=' + myState);
	}
	var cookieString = cookieStringList.join('&');

	// Cookie path:
	var cookiePath = typeof path_root !== "undefined" ? path_root : '';

	// Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
	Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
	Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

	// Trigger event:
	$(window).trigger('dscookiebar_consentchange');
}

var dscookiebar_current_page_tracked = false;
var dsGtag;
function dscookiebar_load_and_fire_tracking_calls()
{

	// No tracking if backend user is logged in:
	if(typeof dp_prevent_count !== "undefined") return false;

	// dp_ga_uid set and has content?
	if(typeof dp_ga_uid === 'undefined' || dp_ga_uid.length < 1) return false;

	if(dscookiebar_current_page_tracked === false)
	{
		dscookiebar_current_page_tracked = true;
		dscookiebar_LoadJsCssFile('https://www.googletagmanager.com/gtag/js?id=' + dp_ga_uid, 'js', function () {
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', dp_ga_uid, { 'anonymize_ip': true });
			dsGtag = gtag;
		});
	}
}


// File loader:
function dscookiebar_LoadJsCssFile(filename, filetype, callback){
	if (filetype=="js"){ //if filename is a external JavaScript file
		var fileref=document.createElement('script')
		fileref.setAttribute("type","text/javascript")
		fileref.setAttribute("src", filename)
	}
	else if (filetype=="css"){ //if filename is an external CSS file
		var fileref=document.createElement("link")
		fileref.setAttribute("rel", "stylesheet")
		fileref.setAttribute("type", "text/css")
		fileref.setAttribute("href", filename)
	}

	if(typeof callback === "function")
	{
		fileref.onload = callback;
	}

	if (typeof fileref!="undefined")
		document.getElementsByTagName("head")[0].appendChild(fileref)
}