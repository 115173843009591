import {formValidation, plugins} from "./lib/formvalidation_1_8_1/es6/index";
import Bootstrap5 from "./lib/formvalidation_1_8_1/es6/plugins/Bootstrap5";


import '../../node_modules/blueimp-file-upload/js/vendor/jquery.ui.widget.js'; // blueimp jquery file upload vendor plugins
import '../../node_modules/blueimp-tmpl/js/tmpl.js'; // blueimp jquery file upload vendor plugins
import '../../node_modules/blueimp-canvas-to-blob/js/canvas-to-blob.js'; // blueimp jquery file upload vendor plugins
import '../../node_modules/blueimp-load-image/js/load-image.all.min.js'; // blueimp jquery file upload vendor plugins
import '../../node_modules/blueimp-file-upload/js/jquery.iframe-transport.js'; // blueimp jquery file upload additions
import '../../node_modules/blueimp-file-upload/js/jquery.fileupload.js'; // blueimp jquery file upload main
import '../../node_modules/blueimp-file-upload/js/jquery.fileupload-ui.js'; // blueimp jquery file upload UI
import '../../node_modules/blueimp-file-upload/js/jquery.fileupload-process.js'; // blueimp jquery file upload additions
import '../../node_modules/blueimp-file-upload/js/jquery.fileupload-image.js'; // blueimp jquery file upload additions
// import '../../node_modules/blueimp-file-upload/js/jquery.fileupload-video.js'; // blueimp jquery file upload additions
// import '../../node_modules/blueimp-file-upload/js/jquery.fileupload-audio.js'; // blueimp jquery file upload additions
import '../../node_modules/blueimp-file-upload/js/jquery.fileupload-validate.js'; // blueimp jquery file upload additions


import * as Ladda from 'ladda';
import _ from "underscore";
import './lib/jquery.form';

// Debug to console on/off:
let debugFileUpload = false;

export function dsApplicationFormSizeTextarea(){

    $('.application-form').each(function () {
       if($(this).find('textarea.size-to-col').length > 0)
       {
           var $ta = $(this).find('textarea.size-to-col');
           var $r = $ta.closest('form');
           var $refCol = $r.find('.measure-col');
           var $refField = $r.find('.measure-input');

           var measureColPosition = $refCol.offset().top;
           var measureFieldPosition = $refField.offset().top;
           var blockHeight = measureFieldPosition - measureColPosition + $refField.outerHeight();

           $ta.css('height', blockHeight + 'px');

       }
    });
}

export function dsInitApplicationFormValidation($form){

    // Detect form step:
    var stepId = parseInt($form.parent().data('formstep'));
    if(stepId == 2)
    {
        // Collapse form:
        $form.slideUp(0);
    }


    var form = $form.get(0);

    $form.find("textarea").each(function () {
        this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
    }).on("input", function () {
        this.style.height = "auto";
        this.style.height = (this.scrollHeight) + "px";
    });

    ds81_init_fileListUpload();

    var fields = {
        applicant_email: {
            validators: {
                notEmpty: {
                    message: 'Bitte gib deine E-Mail-Adresse ein.'
                },
                emailAddress: {
                    message: 'Die eingegebene E-Mail-Adresse ist ungültig.'
                },
                srv: {}
            }
        },
        application_files: {
            validators: {
                srv: {}
            }
        },
        // cb_dp: {
        //     validators: {
        //         notEmpty: {
        //             message: 'Zum Versand dieser Nachricht müssen Sie der Verarbeitung der eingegebenen Daten zustimmen.'
        //         },
        //         srv: {}
        //     }
        // },
        // i_superfield: {
        //     enabled: false,
        //     validators: {
        //         stringLength: {
        //             max: 0,
        //             min: 0,
        //             message: 'Dieses Feld darf keinen Inhalt haben!'
        //         }
        //     }
        // }
    };

    var fv = formValidation(
            form,
            {
                fields: fields,
                plugins: {
                    bootstrap: new Bootstrap5({
                        rowSelector: '.mb-3',
                    }),
                    submitButton: new plugins.SubmitButton(),
                    sequence: new plugins.Sequence({
                        enabled: true,
                    }),
                    declarative: new plugins.Declarative({
                        html5Input: false
                    }),
                    trigger: new plugins.Trigger({
                        event: {
                            applicant_email: 'blur change keyup',
                            cb_dp_nl: 'change',
                        },
                        threshold: 3,
                        delay: 300,
                    }),
                    excluded: new plugins.Excluded({
                        excluded: function(field, element, elements) {

                            // Check if field is hidden:
                            if($(element).is(':hidden'))
                            {
                                return true;
                            }

                            // Check if field is empty:
                            if($(element).data('fv-excluded-if-empty') === true)
                            {
                                // Enable validation if field has value:
                                if($(element).val().length > 0)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }
                            else
                            {
                                // Always required.
                                return false;
                            }
                        }
                    }),
                },
            })
        .on('core.form.valid', function() {

            // Add ladda button:
            if($form.find('.btn-submit.ladda-button').length > 0)
            {
                var l = Ladda.create( $form.find('.btn-submit.ladda-button').get(0) );
                l.start();
                $form.data('myLadda', l);
            }

            // Detect form step:
            var stepId = parseInt($form.parent().data('formstep'));

            // Form transition stage 1 -> stage 2?
            if(stepId == 1)
            {
                $form.slideUp(300).animate({opacity: 0}, 300, function () {
                   $(this).hide();
                });

                // Next form ref:
                var $nextForm = $form.closest('.apply-teaser').find('[data-formstep="2"] form');

                // Copy email address:
                $nextForm.find('input[name="applicant_email"]').val($form.find('input[name="applicant_email"]').val());

                $nextForm.slideDown(300);
            }

            else
            {

                // Collect uploads:
                var uploadsList = [];
                $form.find('.template-download[data-fileid]').each(function () {
                   uploadsList.push($(this).data('fileid'));
                });

                // Submit form:
                $form.ajaxSubmit({
                    url: $form.attr('action'),
                    dataType: 'json',
                    method: 'post',
                    data: {
                        uploads: uploadsList
                    },
                    success: function(responseData, statusText, xhr, $form) {

                        // TODO: Improve whole success handling!

                        if(responseData.result == 'ok')
                        {



                            var $formStep = $form.parent();
                            var $thankYou = $('[data-formstep="thankyou"]');

                            $formStep.slideUp(300).animate({opacity: 0}, 300, function () {
                                $(this).hide();


                            });
                            $thankYou.slideDown(300);


                            // Scroll:
                            var $item = $('#applynow').first();
                            var scrollDist = $('main').scrollTop() + Math.floor($item.offset().top);
                            var harmonizedScrollDuration = parseInt(Math.floor(scrollDist * .5));
                            $('main').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);
                            $('body').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);
                            $('html').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);



                        }
                        else {

                            // Get server error message:
                            if (typeof responseData.errormessage !== "undefined")
                            {
                                // Stop ladda;
                                l.stop();

                                // Get server error message:
                                var errorMessage = responseData.errormessage;

                                // Show message:
                                $form.find('.main-form-error-out').html(errorMessage);

                            }
                            else if (typeof responseData.fielderrors !== "undefined")
                            {
                                // Stop ladda;
                                l.stop();

                                // Serverseitig erkannte Feldfehler bearbeiten (Fehler anzeigen):
                                for(var i in responseData.fielderrors)
                                {

                                    if(responseData.fielderrors[i].length > 0)
                                    {
                                        fv
                                            // Update the message option
                                            .updateValidatorOption(
                                                i, 'srv', 'message', responseData.fielderrors[i]
                                            )
                                            // Set the field as invalid
                                            .updateFieldStatus(i, 'Invalid', 'srv');

                                    }
                                }

                            }
                            else
                            {
                                l.stop();

                                // Get server error message:
                                var errorMessage = $form.find('.main-form-error-out').data('message-servererror');

                                // Show message:
                                $form.find('.main-form-error-out').html(errorMessage);

                            }

                        }


                    },
                    error: function(xhr, status, error, $form)
                    {
                        // Stop ladda;
                        l.stop();

                        // Get server error message:
                        var errorMessage = $form.find('.main-form-error-out').data('message-servererror');

                        // Show message:
                        $form.find('.main-form-error-out').addTimedMessage('danger', errorMessage);

                    }
                });
            }



        }).on('core.form.invalid', function() {
            $(window).trigger('resize.dsApplicationFormSizeTextarea');
            setTimeout(dsApplicationFormSizeTextarea, 10);
        }).on('core.form.validating', function() {
            $(window).trigger('resize.dsApplicationFormSizeTextarea');
            setTimeout(dsApplicationFormSizeTextarea, 10);
        }).on('core.form.validated', function() {
            $(window).trigger('resize.dsApplicationFormSizeTextarea');
            setTimeout(dsApplicationFormSizeTextarea, 10);
        }).on('core.form.valid', function() {
            $(window).trigger('resize.dsApplicationFormSizeTextarea');
            setTimeout(dsApplicationFormSizeTextarea, 10);
        });

    $form.data('form-fv', fv);

}
var uploadIsRunning = false;
function ds81_init_fileListUpload() {
    $('.upload-filelist').each(function () {
        // Empty list? Add template.
        if($(this).find('.item-list-target .upload-item').length < 1)
        {
            ds81_fileListUpload_addUploadItemBlock($(this).find('.item-list-target'));
        }

    });
}


function ds81_fileListUpload_addUploadItemBlock($target) {

    // Clear:
    $target.html('');

    // Get target root:
    var $root = $target.closest('.upload-filelist');

    // Generate unique field name prefix:
    var fieldNamePrefix = 'fup_f_' + _.uniqueId();

    // Get item upload main template:
    var mainTemplateHtml = $($root.find('.fup_template_uploaditem')).html();

    // Prepare main template:
    var mainTemplate = _.template(mainTemplateHtml);

    // Render main template:
    var mainTemplateRendered = mainTemplate({
        itemId: fieldNamePrefix
    });

    // Add rendered output to target:
    $target.append(mainTemplateRendered);

    // Reference new item node:
    var $newItem = $target.find('[data-itemid="' + fieldNamePrefix + '"]').first();

    // Prefill set?
    if(typeof $root.data('prefill') !== "undefined" && $root.data('prefill') !== false)
    {
        var prefillData = $root.data('prefill');
        $root.data('prefill', false);

        // Render prefill template if prefill is present:
        var $configRoot = $root.closest('.upload-config-root');

        var filesList = new Array();
        prefillData.prefill = true;
        filesList[0] = prefillData;
        var tplObject = $.extend({}, {
            files: filesList,
            formatFileSize: function (bytes) {
                if (typeof bytes !== 'number') {
                    return '';
                }
                if (bytes >= 1000000000) {
                    return (bytes / 1000000000).toFixed(2) + ' GB';
                }
                if (bytes >= 1000000) {
                    return (bytes / 1000000).toFixed(2) + ' MB';
                }
                return (bytes / 1000).toFixed(2) + ' KB';
            }
        });
        $newItem.find('.files').append(tmpl($configRoot.data('template-download'), tplObject));
        $newItem.find('.file-upload-initloader').remove();

        // Link delete buttons:
        $newItem.find('.btn.btn-remove-file').each(function () {
            if(!$(this).hasClass('btn-init-done'))
            {
                $(this).addClass('btn-init-done');

                $(this).on('click', function () {

                    if($(this).hasClass('disabled'))
                    {
                        return false;
                    }

                    $(this).addClass('disabled');

                    var $globalRoot = $(this).closest('.upload-config-root');
                    var deleteEndpoint = $globalRoot.data('endpoint-delete-file');
                    var $myRoot = $(this).closest('.template-download');
                    var myFileId = $myRoot.data('fileid')

                    var _this = $(this);


                    $.ajax(
                        deleteEndpoint + myFileId,
                        {
                            type: 'post',
                            data: {

                            },
                            context: $target,
                            dataType: 'json',
                            success: function(data, textStatus, jqXHR){ // AJAX was ok

                                $myRoot.animate({'opacity': 0}, 300, function () {

                                    // Remove item:
                                    $(this).remove();

                                    // Handle max files reached after item has been removed:
                                    var $itemRoot = $myRoot.closest('.upload-item');
                                    ds81_imageupload_handle_max_files_limit($itemRoot);
                                });


                            },
                            error: function(jqXHR, textStatus, errorThrown){  // Severe error (e.g. missing connectivity)
                                _this.removeClass('disabled');

                                // Handle max files reached:
                                var $itemRoot = $myRoot.closest('.upload-item');
                                ds81_imageupload_handle_max_files_limit($itemRoot);

                            }
                        }
                    );



                }).css('cursor', 'pointer');

            }
        });

    }
    else
    {
        // Clear image reference:
        $root.find('input.imageid').val('');

        // Get reference to file upload block element and upload button:
        var $fileUploadBlock = $newItem.find('.file-upload-block').first();

        // Initialize upload element.
        ds81_imageupload_init_container($fileUploadBlock);
    }

    // Trigger event:
    $(window).trigger('ds_filelist_upload_init');

}

function ds81_imageupload_init_container($target)
{

    // Get config root to determine upload config name:
    var $uploadRoot = $target.closest('.upload-config-root');

    // Upload item block:
    var $uploadItem = $target.closest('.upload-item');

    // Upload item block:
    var $uploadFileList = $uploadRoot.find('.upload-filelist').first();

    // Upload type id:
    var typeId = $uploadRoot.data('upload-type-id');

    // upload session getter endpoint:
    var endpointGetSessionUrl = $uploadRoot.data('endpoint-getsession');

    // upload session getter endpoint:
    var endpointUploadUrl = $uploadRoot.data('endpoint-upload');
    // Create session:
    $.ajax(
        endpointGetSessionUrl,
        {
            type: 'post',
            data: {

            },
            context: $target,
            dataType: 'json',
            success: function(data, textStatus, jqXHR){ // AJAX was ok

                // Set context:
                $target = $(this);

                // Get config root to determine upload config name:
                $uploadRoot = $target.closest('.upload-config-root');

                // Upload item block:
                $uploadItem = $target.closest('.upload-item');

                // Upload item block:
                $uploadFileList = $uploadRoot.find('.upload-filelist').first();

                // success? show error otherwise.
                if(data.status == 'ok')
                {

                    var sessiondata = data;

                    // Hide init text:
                    $target.find('.file-upload-initloader').addClass('d-none');

                    // Show upload field:
                    $target.find('.file-upload-main').removeClass('d-none');

                    // Store token:
                    $target.attr('data-upload-uid', data.uid);

                    // Store config:
                    $target.attr('data-upload-config', data.config);

                    // Set singular/plural texts:
                    if(parseInt(data.config.max_files) === 1)
                    {
                        $uploadItem.find('.opt-plural').addClass('d-none');
                        $uploadItem.find('.opt-singular').removeClass('d-none');
                    }
                    else
                    {
                        $uploadItem.find('.opt-plural').removeClass('d-none');
                        $uploadItem.find('.opt-singular').addClass('d-none');
                    }

                    // Configure upload:
                    var $targetElement = $target.find('input[type="file"]').first();
                    $targetElement.fileupload({
                        url: endpointUploadUrl,
                        dataType: 'json',
                        paramName: 'files[]',
                        autoUpload: true,
                        acceptFileTypes: new RegExp("(\.|\/)(" + data.config.allowed_file_types.join('|') + ")$", "i"),
                        maxFileSize: data.config.max_filesize,
                        sequentialUploads: true,
                        maxNumberOfFiles: data.config.max_files,
                        // Enable image resizing, except for Android and Opera,
                        // which actually support image resizing, but fail to
                        // send Blob objects via XHR requests:
                        // disableImageResize: /Android(?!.*Chrome)|Opera/
                        //     .test(window.navigator.userAgent),
                        previewMaxWidth: 500,
                        previewMaxHeight: 500,
                        previewCrop: false,
                        previewCanvas: false,
                        // imageMaxWidth: 3500,
                        // imageMaxHeight: 3500,
                        // imageCrop: false,
                        formData: {
                            token: data.uid
                        },
                        dropZone: $uploadItem,
                        messages: {
                            maxNumberOfFiles: Translation['FileUpload/Messages/MaxNumberOfFilesExceeded'],
                            acceptFileTypes:  Translation['FileUpload/Messages/FileTypeInvalid'],
                            maxFileSize:  Translation['FileUpload/Messages/MaxFilesizeExceeded'],
                            minFileSize:  Translation['FileUpload/Messages/MinFileSizeNotReached']
                        },
                        filesContainer: $uploadItem.find('.files'),
                        disableImagePreview: true,
                        uploadTemplateId: $uploadRoot.data('template-upload'),
                        downloadTemplateId: $uploadRoot.data('template-download')
                    }).bind('fileuploadprogress', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadprogress', e, data, data._progress);
                        }

                        // Push progress from aria-valuenow to style width:
                        if(typeof data._progress !== "undefined")
                        {
                            var progressFloatVal = (100 / data._progress.total) * data._progress.loaded;
                            $(data.context).find('.progress-bar').removeClass('d-none');
                            $(data.context).find('.progress-bar .progress').css('width', progressFloatVal + '%');
                        }

                    }).bind('fileuploaddestroyed', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploaddestroyed', e, data);
                        }

                        // Handle max files reached:
                        var $itemRoot = $(this).closest('.upload-item');
                        ds81_imageupload_handle_max_files_limit($itemRoot);

                    }).bind('fileuploadfailed', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadfailed', e, data);
                        }

                        // Handle max files reached:
                        var $itemRoot = $(this).closest('.upload-item');
                        ds81_imageupload_handle_max_files_limit($itemRoot);

                        // Show error:
                        $(data.context).addClass('has-error');
                        $(data.context).find('.progress-bar').addClass('d-none');
                        $(data.context).find('.error').text(navigator.onLine ? Translation['FileUpload/Messages/UploadErrorGeneric'] : Translation['FileUpload/Messages/UploadErrorNetworkDisconnect']);

                        // Link delete buttons:
                        var $itemRoot = $(this).closest('.upload-item');
                        $itemRoot.find('.btn.btn-remove-file').each(function () {
                            if(!$(this).hasClass('btn-init-done'))
                            {
                                $(this).addClass('btn-init-done');

                                $(this).on('click', function () {

                                    if($(this).hasClass('disabled'))
                                    {
                                        return false;
                                    }

                                    $(this).addClass('disabled');

                                    var $globalRoot = $(this).closest('.upload-config-root');
                                    var $myRoot = $(this).closest('.template-download');

                                    var _this = $(this);

                                    $myRoot.animate({'opacity': 0}, 300, function () {

                                        var $itemRoot = $myRoot.closest('.upload-item');

                                        $(this).remove();

                                        // Handle max files reached after item has been removed:
                                        ds81_imageupload_handle_max_files_limit($itemRoot);
                                    });


                                }).css('cursor', 'pointer');

                            }
                        });



                    }).bind('fileuploadprocessfail', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadprocessfail', e, data);
                        }

                        // Error set? Add class to file item:
                        if(typeof(data.files[data.index].error) !== "undefined")
                        {
                            $(data.context).addClass('has-error');
                            $(data.context).find('.progress-bar').addClass('d-none');
                        }

                    }).bind('fileuploadadd', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadadd', e, data);
                        }

                        // Handle max files reached:
                        var $itemRoot = $(this).closest('.upload-item');
                        ds81_imageupload_handle_max_files_limit($itemRoot);

                    }).bind('fileuploadsubmit', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadsubmit', e, data);
                        }

                        // Get root:
                        var $groupRoot = $(e.currentTarget).closest('.upload-group-block');

                        if(typeof $groupRoot.data('current-category-uid') !== "undefined" && typeof $groupRoot.data('source-id-prefix') !== "undefined")
                        {
                            data.formData = {
                                token: sessiondata.uid,
                                source_id: $groupRoot.data('source-id-prefix') + $groupRoot.data('current-category-uid')
                            };
                        }

                    }).bind('fileuploadstart', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadstart', e, data);
                        }

                        uploadIsRunning = true;

                        var $itemRoot = $(this).closest('.upload-item');

                        var $sb = $itemRoot.closest('form').find('button.btn-submit').first();
                        $sb.attr('disabled', 'disabled').addClass('disabled');

                        // Handle max files reached:
                        ds81_imageupload_handle_max_files_limit($itemRoot);

                    }).bind('fileuploadstop', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadstop', e, data);
                        }

                        uploadIsRunning = false;

                        var $itemRoot = $(this).closest('.upload-item');

                        var $sb = $itemRoot.closest('form').find('button.btn-submit').first();
                        $sb.removeAttr('disabled').removeClass('disabled');


                    }).bind('fileuploadcompleted', function (e, data) {

                        // Debug:
                        if(debugFileUpload === true)
                        {
                            console.log('FUP event fileuploadcompleted', e, data);
                        }

                        var $itemRoot = $(this).closest('.upload-item');

                        var $sb = $itemRoot.closest('form').find('button.btn-submit').first();
                        $sb.removeAttr('disabled').removeClass('disabled');

                        // Get root:
                        var $root = $(this).closest('.upload-group-block');

                        // Get target: Default is .meda-block-root.
                        var $appendTarget = $root.find('.media-block-root');

                        if(typeof data.result.files[0].error !== "undefined")
                        {
                            // ds81_imageUpload_addUploadItemBlock($(this).closest('.item-list-target'));

                            // Remove upload button:
                            $itemRoot.find('.file-upload-main').show();
                            $itemRoot.find('.files').html('');

                            // Restore dropzone:
                            $itemRoot.addClass('dropzone');
                            $fileButton.fileupload('option', 'dropZone', $itemRoot);

                            // Get form validator instance:
                            var $form = $itemRoot.closest('form');
                            var fv =  $form.data('form-fv');

                            // Determine field name:
                            var myFieldName = $itemRoot.closest('.upload-media-image').data('listname');

                            // Set error:
                            fv
                                // Update the message option
                                .updateValidatorOption(
                                    myFieldName, 'srv', 'message', data.result.files[0].error
                                )
                                // Set the field as invalid
                                .updateFieldStatus(myFieldName, 'Invalid', 'srv');

                        }
                        else
                        {

                            var $mainRoot = $(this).closest('.upload-filelist');

                            // Link delete buttons:
                            var $itemRoot = $(this).closest('.upload-item');
                            $itemRoot.find('.btn.btn-remove-file').each(function () {
                                if(!$(this).hasClass('btn-init-done'))
                                {
                                    $(this).addClass('btn-init-done');

                                    $(this).on('click', function () {

                                        if($(this).hasClass('disabled'))
                                        {
                                            return false;
                                        }

                                        $(this).addClass('disabled');

                                        var $globalRoot = $(this).closest('.upload-config-root');
                                        var deleteEndpoint = $globalRoot.data('endpoint-delete-file');
                                        var $myRoot = $(this).closest('.template-download');
                                        var myFileId = $myRoot.data('fileid')

                                        var _this = $(this);

                                        $.ajax(
                                            deleteEndpoint + myFileId,
                                            {
                                                type: 'post',
                                                data: {

                                                },
                                                context: $target,
                                                dataType: 'json',
                                                success: function(data, textStatus, jqXHR){ // AJAX was ok

                                                    $myRoot.animate({'opacity': 0}, 300, function () {

                                                        var $itemRoot = $myRoot.closest('.upload-item');

                                                        $(this).remove();

                                                        // Handle max files reached after item has been removed:
                                                        ds81_imageupload_handle_max_files_limit($itemRoot);
                                                    });

                                                },
                                                error: function(jqXHR, textStatus, errorThrown){  // Severe error (e.g. missing connectivity)
                                                    _this.removeClass('disabled');

                                                    // Handle max files reached after item has been removed:
                                                    var $itemRoot = $myRoot.closest('.upload-item');
                                                    ds81_imageupload_handle_max_files_limit($itemRoot);
                                                }
                                            }
                                        );



                                    }).css('cursor', 'pointer');

                                }
                            });


                            // Trigger change event (adds cropbox):
                            $(window).trigger('ds_media_upload_changed');

                            // Get form validator instance:
                            $form = $itemRoot.closest('form');
                            var fv =  $form.data('form-fv');

                            // Determine field name:
                            var myFieldName = $itemRoot.closest('.upload-filelist').data('listname');

                            // console.log("FUP COMPLETE", myFieldName, fv);
                            // Revalidate field
                            fv.revalidateField(myFieldName).then(function (stat){
                                // console.log("FV RES", stat);
                            });

                        }

                        // Handle max files reached:
                        var $itemRoot = $(this).closest('.upload-item');
                        ds81_imageupload_handle_max_files_limit($itemRoot);

                    });

                }
                else
                {

                    // Show error:
                    $target.find('.file-upload-initloader').addClass('d-none');
                    $target.find('.file-upload-error-init').removeClass('d-none');
                    $uploadItem.find('.type-select').addClass('d-none');

                }

            },
            error: function(jqXHR, textStatus, errorThrown){  // Severe error (e.g. missing connectivity)

                // Show error:
                $target.find('.file-upload-initloader').addClass('d-none');
                $target.find('.file-upload-error-init').removeClass('d-none');
                $uploadItem.find('.type-select').addClass('d-none');

            }
        }
    );


}

function ds81_imageupload_handle_max_files_limit($target)
{

    // Get file upload instance:
    var $fileButton = $target.find('input[type="file"').first();

    // Get root:
    var $itemRoot = $fileButton.closest('.upload-item');

    // Initialization done. Check for prefill:
    var fileListTableLength = $itemRoot.find('.files > .template-download, .files > .template-upload').length;
    var maxNumberOfFiles = $fileButton.fileupload('option', 'maxNumberOfFiles');

    if(debugFileUpload === true)
    {
        console.log('FUP max files handler', '$itemRoot', $itemRoot, 'allowed', maxNumberOfFiles, 'inTableCount', fileListTableLength);
    }

    if(fileListTableLength >= maxNumberOfFiles)
    {
        // File list length is exceeded:

        // Disable drag&drop:
        // $itemRoot.removeClass('dropzone');
        // $fileButton.fileupload('option', 'dropZone', null);

        // Hide drag&drop hint:
        $itemRoot.find('.drag-drop-hint').addClass('disabled');

        // Disable buttons:
        $itemRoot.find('.fileinput-button').addClass('disabled');
        $itemRoot.find('.fileinput-button input').attr('disabled', 'disabled');

    }
    else
    {
        // Some error occured. Back to normal mode.
        $itemRoot.addClass('dropzone');
        $fileButton.fileupload('option', 'dropZone', $itemRoot);
        $itemRoot.find('.drag-drop-hint').removeClass('disabled');
        $itemRoot.find('.fileinput-button').removeClass('disabled');
        $itemRoot.find('.fileinput-button input').removeAttr('disabled');

    }
}
